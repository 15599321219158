$color-primary-blue: #2e496b;
$colorFont: #ffffff;
$color-primary-red: #cc3d45;
$color-error: #dc3545;

.createPlayer__container {
  min-height: 80vh;
  width: 100%;
  padding: 10px;
}

.CreatePlayer {
  display: flex;
  font-weight: bold;
  color: $color-primary-blue;
  text-transform: uppercase;
  font-size: 0.8rem;
  flex-direction: column;
  background-color: #f3f3f3;
  box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.27);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &__title {
    background-color: $color-primary-blue;
    color: $colorFont;
    width: 100%;
    margin: 0 auto 0.2rem auto;
  }

  &__form {
    width: 100%;
    display: flex;
  }
  &__button {
    display: block;
    width: 15rem;
    height: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    background-color: $color-primary-blue;
    color: $colorFont;
    margin: 0.4rem auto 1rem auto;
    border: none;
  }

  &__button:hover {
    background-color: $color-primary-red;
  }

  .form__general {
    display: flex;
    width: 50%;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
  }

  .form__evaluation {
    border-left: 2px solid $color-primary-blue;
    width: 40%;
    text-align: center;
    margin: 0 auto;
  }
  .evaluation__title {
    margin-bottom: 1rem;
    display: block;
    text-align: center;
    font-size: 1rem;
  }
  .evaluation__row {
    margin: 1rem auto 2rem auto;
  }

  .general__basic,
  .general__achievements,
  .general__athletic {
    padding: 0.4rem;
    margin-bottom: 0.5rem;
  }

  .general__basic,
  .general__achievements {
    border-bottom: 2px solid $color-primary-blue;
  }

  .validationError {
    font-weight: normal;
    color: $color-error;
    text-transform: none;
    padding-bottom: -1rem;
  }
  .add_video {
    font-size: 1.3rem;
    margin-left: 1rem;
    cursor: pointer;
  }
  .remove_link {
    font-size: 1.2rem;
    cursor: pointer;
    line-height: inherit;
  }

  div.col-9 {
    margin: 0 auto;
    padding: 0;
  }

  div.col-6 {
    margin: 0 auto;
  }

  select.form-control {
    margin: 0;
    padding: 0;
    height: 1.8rem;
  }
  .image__file {
    height: 200px;
    width: 200px;
  }
  input.form-control {
    text-align: center;
    height: 1.8rem;
  }

  input.form-control::placeholder {
    font-size: 0.9rem;
  }

  @media (max-width: 768px) {
    .CreatePlayer {
      display: block;
    }

    &__form {
      display: block;
    }
    .form__general {
      display: block;
      width: 90%;
    }
    .form__evaluation {
      display: block;
      width: 90%;
      border-top: 2px solid $color-primary-blue;
      border-left: none;
    }

    div.col-6 {
      flex: none;
      max-width: 100%;
    }
    div.col-9 {
      flex: none;
      max-width: 100%;
    }
  }

  @media (max-width: 400px) {
    &__title {
      margin: 1rem auto 0.3rem auto;
    }
  }
}
