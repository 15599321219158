.Loader__container {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Loader {
  position: relative;
}

.Loader__image {
  width: 180px;
  height: 100px;
  animation: bounce 2s infinite ease-in-out;
}

@keyframes bounce {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
}
