$color-primary-blue: #2e496b;
$colorFont: #ffffff;
$color-primary-red: #cc3d45;
$color-primary-gradient: linear-gradient(45deg, $color-primary-blue, #1a2a3b);

.Footer {
  background: $color-primary-gradient;
  height: 10vh;
  width: 100vw;
  color: $colorFont;
  bottom: 0;
  position: relative;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.3);

  .footer__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .footer__social {
    margin: 1% 0 0 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .social__list {
    margin-bottom: 1rem;
    display: flex;
    gap: 20px;
  }

  .list__redirection i {
    margin-right: 0;
    font-size: 35px;
    transition: transform 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
    color: $colorFont;
  }

  .list__redirection:hover i {
    color: $color-primary-red;
  }

  .footer__info {
    margin-bottom: 0.5rem;
    font-size: 0.7rem;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 500px) {
    .list__redirection i {
      font-size: 25px;
    }

    .social__list {
      margin-bottom: 0.2rem;
    }

    .footer__info {
      margin-bottom: 0.2rem;
    }
  }
}
