.modal.show .modal-dialog {
  width: 50%;
  max-width: 800px;
  text-align: justify;
  font-size: 0.9rem !important;
}

@media (max-width: 900px) {
  .modal.show .modal-dialog {
    width: 70%;
  }
  .modal-body {
    font-size: 0.8rem !important;
  }
}
@media (max-width: 575px) {
  .modal.show .modal-dialog {
    width: 90%;
    margin: 2rem auto;
  }
  div.modal-title {
    font-size: 1.2rem;
  }
  .modal-body {
    font-size: 0.7rem !important;
  }
}
