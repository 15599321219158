.carousel-item {
  text-align: center;
}

.carousel-item img {
  max-width: 450px;
  max-height: 450px;
  object-fit: contain;
}

.icon-right,
.icon-left {
  color: black;
}
