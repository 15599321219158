.about__us {
  min-height: 80vh;
  padding: 1rem 1rem;
  text-align: justify;
}

.about__us h4 {
  margin: 2px auto;
  color: #2e496b;
}
.about__us span {
  font-size: 0.9rem;
}

.about__us__image {
  margin: 0 auto;
  width: 360px;
  height: 184px;
}
.about__us__col {
  margin: 0 1rem 0 1rem;
}

.about__us__explanation {
  margin-bottom: 2rem;
  margin: 0 -15px 2rem -15px;
}

.about__us__questions {
  margin-bottom: 1.3rem;
}

.about__us h6 {
  color: #2e496b;
  width: 100%;
}
.carousel__container {
  width: 90%;
  margin: 3rem auto 3rem auto !important;
}
.carousel-item img {
  width: 100%;
  height: auto;
}

@media (max-width: 1088px) {
  .about__us {
    margin-top: 1rem;
  }

  @media (max-width: 767px) {
    .about__us h4 {
      font-size: 1.4rem;
    }
    .about__us__image {
      width: 240px;
      height: 120px;
    }
    .about__us h6 {
      font-size: 0.9rem;
    }
    .about__us span {
      font-size: 0.8rem;
    }

    @media (max-width: 576px) {
      .about__us__row {
        display: block !important;
        width: 100%;
        text-align: justify;
      }
    }
  }
}
