.edit-container {
  display: flex;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-direction: column-reverse;
    align-items: center;
  }
}
.password-edit {
  width: 40%;
  @media (max-width: 991px) {
    width: 45%;
  }
  @media (max-width: 500px) {
    width: 90%;
  }
}
.profile-edit {
  width: 60%;
  @media (max-width: 991px) {
    width: 50%;
  }
  @media (max-width: 500px) {
    width: 90%;
  }
}
