.PlayerPage {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  margin-bottom: 1rem;

  &__name {
    height: 8%;
    width: 100%;
    font-size: 1.5rem;
  }

  &__basicInfo {
    display: flex;
    margin: 1rem 0 0 0;
    height: 35%;
    width: 100%;
  }

  &__sport {
    display: flex;
    margin: 2rem 0;
    height: 45%;
    width: 100%;
  }

  .basicInfo__photo {
    margin: 0 1rem;
    height: 100%;
    width: 50%;
  }

  .basicInfo__information {
    height: 100%;
    width: 50%;
  }

  .sport__school {
    margin: 0 1rem;
    height: 100%;
    width: 50%;
  }

  .physical__features span {
    margin-left: 3px;
  }
  .physical__features {
    font-size: 0.95rem;
    margin-bottom: 3px;
    margin-top: 5px;
  }
  .sport__evaluation {
    height: 100%;
    width: 50%;
  }
  .player__video {
    margin: 0 auto 1rem auto;
    width: 560px;
    height: 290px;
  }
  .video__container {
    margin-top: 1rem;
    text-align: center;
  }

  .fa-heart {
    position: absolute;
    left: 14px;
    font-size: 2rem;
  }
  .favourite {
    color: #cc3d45;
  }
  .expand__contact-player {
    display: block;
    background-color: #cc3d45 !important;
    height: 50px;
    width: 200px;
    padding: 0;
    margin: 10px auto;
    transition: transform 0.2s;
  }
  .expand__contact-player:active {
    transform: scale(0.9);
  }

  .expand__contact-player span {
    font-size: 16px;
  }
  .contact_player_label {
    font-weight: bolder;
  }
  .contact_player_input {
    height: 10rem;
  }
  .validationError {
    color: #dc3545;
  }

  @media (max-width: 768px) {
    .PlayerPage {
      font-size: 0.8rem;
    }
    .PlayerPage__basicInfo {
      display: block;
    }
    .PlayerPage__sport {
      margin: 0;
      display: block;
    }
    .basicInfo__photo {
      margin: 0;
      width: 95%;
      padding: 0 1rem;
    }
    .basicInfo__information {
      width: 95%;
      padding: 0 1rem;
    }
    .sport__school {
      margin: 0;
      width: 95%;
      padding: 1rem 1rem 0 1rem;
    }
    .sport__evaluation {
      width: 95%;
      padding: 1rem 1rem 0 1rem;
    }
  }
  @media (max-width: 670px) {
    .player__video {
      width: 90%;
    }
  }
}
