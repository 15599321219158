@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

.Main {
  height: 80vh;
}

.App {
  font-family: 'Cairo', sans-serif;
  overflow-x: hidden;
  overflow-y: hidden;
}

.navbar .container {
  max-width: 1440px !important;
}

@media (max-width: 767px) {
  .container {
    width: 90% !important;
    max-width: 700px !important;
  }
}
