$color-primary: #2e496b;
$color-primary-hover: #4c75a7;
$colorFont: #fffffff8;

.card {
  box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.27);
}

.card-header {
  color: $colorFont;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #2e496b !important;
  position: relative;
}
.update__player {
  position: absolute;
  right: 0;
}
.favourite-tooltip > .tooltip-inner {
  background-color: #2e496b;
  color: yellow;
  border: none;
}
.favourite-tooltip .arrow {
  visibility: hidden;
}
.update__player:last-child {
  right: 2rem;
}
.deleteId_button,
.detailsId_button {
  position: absolute;
  right: 0;
  color: white;
  cursor: pointer;
}
.detailsId_button {
  right: 2rem;
}

.update__player .fas {
  font-size: 1.2rem;
  color: $colorFont;
  transition: all 0.2s ease-in-out;
}

.update__player .fas:hover {
  font-size: 1.3rem;
  color: $color-primary;
}

.btn-primary,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
  background-color: $color-primary !important;
  border: 0 !important;
  outline: 0 !important;
  text-transform: uppercase;
}
.btn-primary:hover {
  background-color: $color-primary-hover !important;
}

.btn {
  border-radius: 0 !important;
}
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

.list-group-item {
  padding: 0.4rem 1rem !important;
}

.fas,
.far {
  margin-right: 1rem;
  font-size: 1.3rem;
}
.fa-heart {
  position: absolute;
  left: 8px;
}

.fa-venus-mars {
  margin-right: 0.9rem;
}

.fa-arrows-alt-v {
  margin-right: 1.5rem;
}

.item__title {
  font-weight: bolder;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.item__details {
  font-size: 0.9rem;
  margin-left: 1rem;
}

.card-img,
.card-img-top {
  height: 300px;
  object-fit: contain;
}

.card__link {
  display: block;
  width: 100%;
  color: $colorFont;
  text-decoration: none;
}

.card__link:hover {
  color: $colorFont;
  text-decoration: none;
}

.heart {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translate(-10px, -50%);
  text-align: center;
  cursor: pointer;
  font-size: 20px;
}
.favourite {
  color: #cc3d45;
}

@media (max-width: 767px) {
  .card-header {
    font-size: 0.9rem;
    padding: 5px !important;
  }
  .card__link {
    font-size: 0.9rem;
  }
  .item__title {
    font-size: 0.7rem;
  }

  .item__details {
    margin-left: 5px;
  }
}
@media (max-width: 550px) {
  .item__title {
    font-size: 0.9rem;
  }

  .item__details {
    margin-left: 1rem;
  }
  .card-header {
    font-size: 1rem;
  }
}
