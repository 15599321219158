.Login {
  height: 70vh;
}

.Login__form {
  width: 412px;
  margin: 10vh auto;
  background-color: #f3f3f3;
  box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.27);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.Login__form form {
  width: 100%;
  max-width: 410px;
  padding: 15px;
  margin: auto;
}
.Login__form .form-control {
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
  position: relative;
}
.Login__form .form-control:focus {
  z-index: 2;
}
.Login__form .form-signin {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.Login__form .form-signin {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.Login__form .social-login {
  width: 290px;
  margin: 0 auto;
  margin-bottom: 14px;
  text-align: center;
}
.Login__form .social-btn {
  font-weight: 100;
  color: white;
  width: 190px;
  font-size: 0.9rem;
}

.Login__form a {
  display: block;
  padding-top: 10px;
  color: lightseagreen;
}

.loLogin-form .lines {
  width: 200px;
  border: 1px solid red;
}

.Login__form button[type='submit'] {
  margin-top: 10px;
}

.Login__form .google-btn {
  background-color: #df4b3b;
}

.Login__form .form-reset,
.Login__form .form-signup {
  display: none;
}

.Login__form .form-signup .social-btn {
  width: 210px;
}

.Login__form .form-signup input {
  margin-bottom: 2px;
}

.form-signup .social-login {
  width: 210px !important;
  margin: 0 auto;
}

@media screen and (max-width: 500px) {
  .Login__form {
    width: 300px;
  }

  .Login__form .social-login {
    width: 200px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .Login__form .social-btn {
    font-size: 1.3rem;
    font-weight: 100;
    color: white;
    width: 200px;
    height: 56px;
  }
  .Login__form .social-btn:nth-child(1) {
    margin-bottom: 5px;
  }
  .Login__form .social-btn span {
    display: none;
  }
  .Login__form .facebook-btn:after {
    content: 'Facebook';
  }

  .Login__form .google-btn:after {
    content: 'Google+';
  }
}
@media screen and (max-width: 360px) {
  .Login__form {
    width: 280px;
  }
}
