$color-primary: #2e496b;
$color-primary-hover: #4c75a7;
$color-secondary: #cc3d45;
$color-secondary-hover: #e6474f;

.filter__bar {
  justify-content: space-between !important;
  margin-bottom: 3rem;
  margin-top: 1rem;
}

.filter__brand {
  text-transform: uppercase;
  font-size: 1.1rem !important;
  font-weight: bolder;
}

.form__control {
  width: 11rem !important;
  height: 2.9rem !important;
}

.form-group {
  font-weight: bolder;
  font-size: 0.9rem;
  text-transform: uppercase;
  text-align: center;
}

.filter__bar .btn-primary,
.filter__bar .btn-secondary {
  width: 6rem;
  border: 0 !important;
  outline: 0 !important;
  text-transform: uppercase;
  font-size: 1rem !important;
}

.btn-primary {
  background-color: $color-primary !important;

  &:hover {
    background-color: $color-primary-hover !important;
  }
}
.btn-secondary {
  background-color: $color-secondary !important;

  &:hover {
    background-color: $color-secondary-hover !important;
  }
}

@media (max-width: 1200px) {
  .form__control {
    width: 9rem !important;
  }
  select.form__control {
    font-size: 0.9rem;
    padding: 0;
  }
}
@media (max-width: 991px) {
  .filter__bar .btn-primary,
  .filter__bar .btn-secondary {
    width: 10rem;
  }
}

@media (max-width: 767px) {
  .form__control {
    width: 12rem !important;
  }
  select.form__control {
    font-size: 0.8rem;
  }
  .filter__bar {
    flex-direction: column;
    width: 400px;
    margin: 1rem auto 2rem;
  }
  .filter__bar .btn-primary,
  .filter__bar .btn-secondary {
    margin: 5px;
  }
}
@media (max-width: 515px) {
  .filter__bar {
    width: 80%;
  }
}
@media (max-width: 390px) {
  .filter__bar {
    width: 100%;
  }
}
