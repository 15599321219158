$color-fail: #ff3547;
$color-success: #00c851;

.Toastr {
  position: absolute;
  min-width: 300px;
  z-index: 10;
  color: black;
  text-transform: none;
  right: 0;

  .failed {
    background-color: $color-fail;
  }

  .success {
    background-color: $color-success;
  }
}
