$color-primary: #2e496b;
$color-primaryt: #cc3d45;
.Player {
  overflow-x: hidden;
  min-height: 80vh;
}

.Player__pagination {
  padding: 1rem;
  width: 100%;
  height: 5%;
  display: flex;
  justify-content: center;
}

.page-link {
  color: $color-primary !important;
  font-weight: bold;
}

.page-item.active .page-link {
  background-color: #cc3d45 !important;
  border: none !important;
}

@media (max-width: 991px) {
  .players__columns {
    column-count: 2 !important;
  }
}
@media (max-width: 550px) {
  .players__columns {
    column-count: 1 !important;
  }
}

.players__columns {
  display: flex !important;
  flex-wrap: wrap !important;
}

@media (max-width: 600px) {
  .players__columns .card {
    width: 100% !important;
    height: auto !important;
  }
}

@media (min-width: 601px) and (max-width: 991px) {
  .players__columns .card {
    width: 48% !important;
    height: auto !important;
  }
}

@media (min-width: 992px) {
  .players__columns .card {
    width: 31% !important;
    height: auto !important;
  }
}

.players__columns .card .card-body {
  padding: 0 !important;
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}

.players__columns .card .btn-primary {
  margin-top: auto !important;
}
