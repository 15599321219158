.userProfile__name i {
  margin: 5px;
}

.dropdown-toggle::after {
  color: #cc3d45;
}

div.dropdown-menu.show {
  right: -1rem !important;
  left: auto;
}
div.dropdown-menu.show a {
  color: #cc3d45;
}

@media (max-width: 991px) {
  .userProfile__name {
    font-size: 0.9rem;
  }
}
@media (max-width: 766px) {
  .userProfile__name {
    font-size: 1.1rem;
  }
}

.userProfile__name {
  display: flex !important;
  align-items: center;
  font-weight: 700;
}

.navbar-nav .dropdown .dropdown-toggle {
  display: flex !important;
  align-items: center !important;
}
