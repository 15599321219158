$color-app: #bb5353;
$colorFont: #ffffff;
$color-primary: #2e496b;

.modal__message {
  font-size: 1.2rem;
  text-align: center;
  color: $colorFont;
  background-color: $color-app !important;
}

.btn-outline-warning {
  color: $color-app !important;
  border-color: $color-app !important;
}

.btn-outline-warning:hover {
  color: $colorFont !important;
  background-color: $color-app !important;
  border-color: $color-app !important;
}
