.createAccount__form {
  margin: 1rem auto 1rem auto;
  max-width: 500px;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
  box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.27);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.createAccount__form .form-check {
  display: inline-block;
  margin-left: 1rem;
}
.createAccount__title {
  width: 100%;
  margin: 0 auto 0.2rem auto;
  height: 10%;
  background-color: #2e496b;
  color: white;
}
.createAccount__title span {
  font-size: 1.2rem;
  margin: 0 auto;
}
.createAccount__form label.form-check-label {
  text-transform: none;
}
.recaptcha {
  display: flex;
  justify-content: center;
}

.createAccount label {
  font-weight: 400;
}
.createAccount__form label.form-label {
  color: #2e496b;
}
.createAccount input {
  margin: 0 auto;
  width: 80%;
}
.createAccount__form input.form-control,
.createAccount__form select.form-control {
  width: 80%;
  margin: 0 auto;
  height: 50%;
  padding: 0.2rem;
}

.createAccount_section {
  margin: 1rem auto 1rem auto !important;
  border-bottom: 2px solid #2e496b;
  width: 90%;
}
.gmail-logo {
  width: 86px;
  height: 20px;
}
.information-logo {
  margin-left: 8px;
  width: 15px;
  height: 15px;
}

.google-tooltip > .tooltip-inner {
  background-color: #4285f4;
  color: white;
  border: 1px solid #fbbc05;
}

.google-tooltip > .tooltip-arrow {
  border-top: 5px solid #34a853;
}

.createAccount_section:last-of-type {
  border: none;
}

.CreateAccount__button {
  display: block !important;
  width: 40%;
  height: 2.2rem;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #2e496b;
  color: white;
  margin: 0.4rem auto 1rem auto;
  border: none;
}
.section__req {
  color: #dc3545;
}

.createAccount__form .fas {
  font-size: 1.4rem;
}
label.CreateAccount__terms.form-label {
  text-transform: none;
  cursor: pointer;
  color: #cc3d45;
}
.CreateAccount__terms:hover {
  text-decoration: underline;
}

.createAccount__form .validationError {
  text-transform: none;
  font-weight: normal;
  color: #dc3545;
}
@media (max-width: 576px) {
  .createAccount_section {
    width: 100%;
  }
  .createAccount__form input.form-control,
  .createAccount__form select.form-control {
    width: 90%;
  }
}
@media (max-width: 443px) {
  .CreateAccount__button {
    width: 60%;
  }
}
