.faq {
  min-height: 80vh;
  margin-top: 1rem;
}
.faq h4 {
  margin: 2px auto;
  color: #2e496b;
}

.faq__question {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 3rem 1rem 1rem;
  color: #2e496b;
  font-size: 1.15rem;
  font-weight: 400;
  border-bottom: 1px solid #e5e5e5;
}
.accordion a:hover,
.accordion a:hover::after {
  cursor: pointer;
  color: #2e496b;
}
.accordion a:hover::after {
  border: 1px solid #2e496b;
}

.accordion .faq__question::after {
  font-family: 'Ionicons';
  content: '\f0ab';
  position: absolute;
  right: 1rem;
  font-size: 1rem;
  color: #2e496b;
  padding: 3px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #2e496b;
  text-align: center;
}

.content {
  padding: 1rem;
}
