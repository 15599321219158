.summer-trips {
  min-height: 80vh;
  padding: 1rem 1rem;
  text-align: justify;

  .image-gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 20px;

    .image-item {
      position: relative;

      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        cursor: pointer;
        border-radius: 5px;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  @media (max-width: 991px) {
    .image-gallery {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 767px) {
    .image-gallery {
      grid-template-columns: repeat(1, 1fr);
      justify-items: center;
    }
  }
}

.logo-image {
  width: 100%;
  max-width: 150px;
  height: auto;
  display: block;
  margin: 0 auto;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
