$color-primary: #cc3d45;
$color-primary-choosen: #000080;

.Menu {
  height: 10vh;

  .inactive {
    color: $color-primary;
    text-decoration: none;
    border-bottom: none;
    transition: ease 0.2s;
  }

  .active {
    color: $color-primary-choosen;
    text-decoration: none;
  }

  .inactive:hover {
    color: $color-primary;
    text-decoration: none;
  }

  .active:hover {
    color: $color-primary-choosen;
  }

  .nav__link {
    font-family: 'Cairo', sans-serif;
    font-size: 1rem;
    margin-left: 12px;
    font-weight: 700;
  }

  .nav__brand {
    border-bottom: none;
  }

  .navbar-brand {
    padding: 0;
    width: 111px;
    height: 55px;
  }
  @media (max-width: 1191px) {
    .navbar-brand {
      width: 101px;
      height: 50px;
    }
    .nav__link {
      font-size: 0.8rem;
      margin-left: 3px;
    }
  }
  @media (max-width: 930px) {
    .navbar-brand {
      width: 101px;
      height: 50px;
    }
    .nav__link {
      font-size: 0.7rem;
      margin-left: 3px;
    }
  }
  @media (max-width: 766px) {
    .navbar-brand {
      width: 101px;
      height: 50px;
    }
    .nav__link {
      font-size: 1.1rem;
      margin-left: 3px;
    }
  }
}
